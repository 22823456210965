<template>
  <div>
    <v-app>
      <!-- 
      <NavButton />
      <!-->
      <NewNav />
      <v-main>
        <transition name="slide-fade" mode="out-in">
          <router-view />
        </transition>
        <Footer />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import NewNav from '@/components/NewNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',

  data() {
    return {}
  },
  components: {
    NewNav,
    Footer,
  },

  methods: {}
}
</script>

<style>
@media print {
  .nav-header {
    display: none;
  }
  .media-icons {
    display: none;
  }
}

.slide-fade-enter {
  transform: translateX(100px);
  opacity: 0;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
</style>