<template>
  <v-footer padless>
    <v-container>
      <v-row class="media-icons">
        <v-col class="text-center" cols="12">
          <v-btn
            icon
            href="https://www.facebook.com/bornagainstrength/"
            target="_blank"
          >
            <v-icon color="blue">mdi-facebook</v-icon>
          </v-btn>

          <v-btn
            icon
            href="https://www.instagram.com/bornagainstrength/?hl=en"
            target="_blank"
          >
            <v-icon color="red">mdi-instagram</v-icon>
          </v-btn>

          <v-btn icon href="https://twitter.com/bornagainhq" target="_blank">
            <v-icon color="blue">mdi-twitter</v-icon>
          </v-btn>

          <v-btn icon href="tel:5025259343">
            <v-icon color="green">mdi-phone</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="text-center">
        <v-col class="text-center" cols="12">
          <h4>
            <v-icon color="red">mdi-map-marker</v-icon>
            <a
              href="https://goo.gl/maps/ipkFyZztNsR5CFf38"
              class="maptag"
              target="_blank"
              >1800 Elite Drive, Louisville KY 40223
            </a>
          </h4>
          <v-btn text :to="{ name: 'Egg1Alert' }">
            <strong>Born Again Strength</strong> &nbsp;|
            {{ new Date().getFullYear() }}
          </v-btn>

          <h5>All Rights Reserved.</h5>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {}
</script>

<style scoped>
.maptag {
  color: black;
  text-decoration: none;
}
.maptag:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
