<template>
  <div class="nav-header">
    <v-navigation-drawer v-model="drawer" app>
      <v-list nav dense>
        <v-list-item-group active-class="grey--text text--accent-4">
          <v-list-item
            class="px-2 text-center"
            :to="{ name: 'Home' }"
            text
            exact-path
          >
            <v-list-item-avatar class="mx-auto" size="65">
              <v-img src="/images/5100.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item :to="{ name: 'StrengthandConditioning' }">
            <v-list-item-title>Strength and Conditioning</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'PDLanding' }">
            <v-list-item-title>Player Development</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'CageSchedule' }">
            <v-list-item-title>Cage Schedule</v-list-item-title>
          </v-list-item>

          <!-- <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-title>Player Development</v-list-item-title>
            </template>

            <div class="pl-2">
              <v-list-item :to="{ name: 'YouthPrograms' }">
                <v-list-item-title>Youth Programs</v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'HittingPrograms' }">
                <v-list-item-title>Hitting Programs</v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'PitchingPrograms' }" v-if="false">
                <v-list-item-title>Pitching Programs</v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'IndividualTraining' }">
                <v-list-item-title>Individual Training</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group> -->

          <v-list-item :to="{ name: 'IndividualTraining' }">
            <v-list-item-title>Coaches</v-list-item-title>
          </v-list-item>

          <!-- <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-title>Facility</v-list-item-title>
            </template>

            <div class="pl-2">
              <v-list-item :to="{ name: 'RentalInformation' }">
                <v-list-item-title>Rental Information</v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'CageSchedule' }">
                <v-list-item-title>Cage Schedule</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group> -->

          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-title>Events</v-list-item-title>
            </template>

            <div class="pl-2">
              <v-list-item :to="{ name: 'TheCatchingGuy' }">
                <v-list-item-title>The Catching Guy - Camp</v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'BackToSchool' }">
                <v-list-item-title
                  >"Back to School" - Spikeball</v-list-item-title
                >
              </v-list-item>

              <v-list-item :to="{ name: 'TurkeyTurkeyTurkey' }">
                <v-list-item-title>"T3" - Spikeball</v-list-item-title>
              </v-list-item>

              <!-- <v-list-item :to="{ name: 'ChristmasBuddy' }" v-if="true">
                <v-list-item-title>Christmas Buddy</v-list-item-title>
              </v-list-item> -->
            </div>
          </v-list-group>

          <!-- <v-list-item :to="{ name: 'Blog' }" v-if="true">
            <v-list-item-title>Blog</v-list-item-title>
          </v-list-item> -->

          <v-list-item :to="{ name: 'AboutUs' }">
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item-title>
            <v-btn class="mt-3 ml-12" :href="mailto" color="blue" outlined>
              Contact Us
            </v-btn>
          </v-list-item-title>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="grey darken-1" dark app>
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title v-show="!drawer">Born Again Strength</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      mailto:
        'mailto:sydney@bornagainstrength.com?' +
        encodeURI('subject=Send me more Info'),
    }
  },
}
</script>
