import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/strengthandconditioning/',
    name: 'StrengthandConditioning',
    component: () => import('@/views/tabs/StrengthandConditioning.vue'),
  },
  {
    path: '/pdlanding/hittingprograms/',
    name: 'PDLanding',
    component: () => import('@/views/tabs/PlayerDevelopment/PDLanding.vue'),
  },
  {
    path: '/playerdevelopment/hittingprograms/',
    name: 'HittingPrograms',
    component: () =>
      import('@/views/tabs/PlayerDevelopment/HittingPrograms.vue'),
  },
  {
    path: '/playerdevelopment/pitchingprograms/',
    name: 'PitchingPrograms',
    component: () =>
      import('@/views/tabs/PlayerDevelopment/PitchingPrograms.vue'),
  },
  {
    path: '/playerdevelopment/youthprograms',
    name: 'YouthPrograms',
    component: () => import('@/views/tabs/PlayerDevelopment/YouthPrograms.vue'),
  },
  {
    path: '/playerdevelopment/individualtraining/',
    name: 'IndividualTraining',
    component: () =>
      import('@/views/tabs/PlayerDevelopment/IndividualTraining.vue'),
  },
  {
    path: '/pdlanding/andersonmiller/',
    name: 'AndersonMillerPage',
    component: () => import('@/views/tabs/PlayerDevelopment/AndersonMillerPage.vue'),
  },
  {
    path: '/pdlanding/brucebell/',
    name: 'BruceBellPage',
    component: () => import('@/views/tabs/PlayerDevelopment/BruceBellPage.vue'),
  },
  {
    path: '/pdlanding/grantknipp/',
    name: 'GrantKnippPage',
    component: () => import('@/views/tabs/PlayerDevelopment/GrantKnippPage.vue'),
  },
  {
    path: '/pdlanding/caseybrown/',
    name: 'CaseyBrownPage',
    component: () => import('@/views/tabs/PlayerDevelopment/CaseyBrownPage.vue'),
  },
  {
    path: '/blog/',
    name: 'Blog',
    component: () => import('@/views/tabs/Blog.vue'),
  },

  {
    path: '/rentalinformation/',
    name: 'RentalInformation',
    component: () => import('@/views/tabs/RentalInformation.vue'),
  },

  {
    path: '/cageschedule/',
    name: 'CageSchedule',
    component: () => import('@/views/CageSchedule.vue'),
  },

  {
    path: '/login/',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
  },

  {
    path: '/profiledialog/',
    name: 'ProfileDialog',
    component: () => import('@/components/ProfileDialog.vue'),
  },

  {
    path: '/aboutus/',
    name: 'AboutUs',
    component: () => import('@/views/tabs/AboutUs.vue'),
  },
  {
    path: '/blog/successfulathlete',
    name: 'SuccessfulAthlete',
    component: () => import('@/components/Blog/SuccessfulAthlete.vue'),
  },
  {
    path: '/blog/scientistvscaveman',
    name: 'ScientistVsCaveman',
    component: () => import('@/components/Blog/ScientistVsCaveman.vue'),
  },
  {
    path: '/blog/DoublePlaySS',
    name: 'DoublePlaySS',
    component: () => import('@/components/Blog/DoublePlaySS.vue'),
  },
  {
    path: '/egg1alert',
    name: 'Egg1Alert',
    component: () => import('@/views/EasterEgg/Egg1Alert.vue'),
  },
  {
    path: '/egg1landing',
    name: 'Egg1Landing',
    component: () => import('@/views/EasterEgg/Egg1Landing.vue'),
  },
  {
    path: '/egg1task1',
    name: 'Egg1Task1',
    component: () => import('@/views/EasterEgg/Egg1Task1.vue'),
  },
  {
    path: '/egg1task2',
    name: 'Egg1Task2',
    component: () => import('@/views/EasterEgg/Egg1Task2.vue'),
  },
  {
    path: '/egg1task3',
    name: 'Egg1Task3',
    component: () => import('@/views/EasterEgg/Egg1Task3.vue'),
  },
  {
    path: '/egg1prize',
    name: 'Egg1Prize',
    component: () => import('@/views/EasterEgg/Egg1Prize.vue'),
  },
  {
    path: '/egg2alert',
    name: 'Egg2Alert',
    component: () => import('@/views/EasterEgg/Egg2Alert.vue'),
  },
  {
    path: '/egg2landing',
    name: 'Egg2Landing',
    component: () => import('@/views/EasterEgg/Egg2Landing.vue'),
  },
  {
    path: '/egg2task1',
    name: 'Egg2Task1',
    component: () => import('@/views/EasterEgg/Egg2Task1.vue'),
  },
  {
    path: '/egg2task2',
    name: 'Egg2Task2',
    component: () => import('@/views/EasterEgg/Egg2Task2.vue'),
  },
  {
    path: '/egg2task3',
    name: 'Egg2Task3',
    component: () => import('@/views/EasterEgg/Egg2Task3.vue'),
  },
  {
    path: '/egg2prize',
    name: 'Egg2Prize',
    component: () => import('@/views/EasterEgg/Egg2Prize.vue'),
  },
  {
    path: '/legendaryalert',
    name: 'LegendaryAlert',
    component: () => import('@/views/EasterEgg/LegendaryAlert.vue'),
  },
  {
    path: '/legendarylanding',
    name: 'LegendaryLanding',
    component: () => import('@/views/EasterEgg/LegendaryLanding.vue'),
  },
  {
    path: '/legendarytask1',
    name: 'LegendaryTask1',
    component: () => import('@/views/EasterEgg/LegendaryTask1.vue'),
  },
  {
    path: '/turkeyturkeyturkeyspikeballtournament',
    name: 'TurkeyTurkeyTurkey',
    component: () => import('@/views/tabs/Events/TurkeyTurkeyTurkey.vue'),
  },
  {
    path: '/firstannualt3',
    name: 'FirstAnnualT3',
    component: () => import('@/components/Blog/FirstAnnualT3.vue'),
  },
  {
    path: '/backtoschool',
    name: 'BackToSchool',
    component: () => import('@/views/tabs/Events/BackToSchool.vue'),
  },
  {
    path: '/christmasbuddy',
    name: 'ChristmasBuddy',
    component: () => import('@/views/tabs/Events/ChristmasBuddy.vue'),
  },
  {
    path: '/thecatchingguy',
    name: 'TheCatchingGuy',
    component: () => import('@/views/tabs/Camps/TheCatchingGuy.vue'),
  },
  {
    path: '/hiddenduck',
    name: 'HiddenDuck',
    component: () => import('@/views/HiddenDuck.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

export default router
